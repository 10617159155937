import algoliasearch from "algoliasearch/lite"
import React, { useEffect, useState } from "react"
import { usePropertyStore } from "../../components/SearchResults/store/propertyStore"
import { SEOSEARCHRESULTS } from "../../components/seo-search-results"
import useSearchResultsSEO from "../../hooks/useSearchResultsSEO"
import SearchResultsTemplate from "../../templates/property-results"
import createResultsUrl from "../../utils/property-results/createResultsUrl"
import generateFiltersfromPageData from "../../utils/property-results/generateFiltersfromPageData"
import parseResultsUrl from "../../utils/property-results/parseResultsUrl"
// const { useSearchResultsSEO } = require("@starberry/gatsby-library-common/PropertySearch")
// format h1 & desc and send to template
// format seo component
import isbot from "isbot"
export default function SearchResults(props) {
  const [algoliadata, getAlgoliadata] = useState([])
  const { reset, clearFilters } = usePropertyStore()
  // const [mysortbyoption, setMysortbyoption] = useState('');
  // const [mypageoption, setMypageoption] = useState('');

  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  // navigate to pages based on filters

  const { pageh1, introcopy } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  let realuser = true

  useEffect(() => {
    // getAlgoliaResutls(myalgoliafilters)
    if (typeof window !== "undefined") {
      realuser =
        process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true"
          ? isbot(navigator.userAgent)
          : false
          
    }
    if (realuser === false) {
      getAlgoliaResutls(myalgoliafilters)
    } else {
      // getBotSearchResult()
    }
    if (
      props?.location?.pathname ===
        "/properties/for-sale/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/" ||
      props?.location?.pathname ===
        "/properties/to-rent/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/"
    ) {
      reset()
      clearFilters()
    }
  }, [props.location])
  // const getBotSearchResult = () => {
  //   let $where = `_where[publish]=true&[department]=${page_url_data["departmentVal"]}&[search_type]=${page_url_data["searchtypeVal"]}`

  //   let status_arr = page_url_data["agreedVal"]
  //   if (page_url_data["agreedVal"]) {
  //     if (status_arr.length > 0) {
  //       status_arr.map((status, ind) => {
  //         $where += `&[_or][${ind}][status_contains]=${status}`
  //       })
  //     }
  //   }

  //   let sorting = `sort_date:DESC,status:ASC`

  //   let area_name = page_url_data["areaVal"]
  //   //console.log("area_name", area_name)
  //   if (area_name.length > 0) {
  //     if (area_name[0] === process.env.GATSBY_DEFAULT_AREA.toLowerCase()) {
  //       $where += ""
  //     } else {
  //       if (typeof area_name === "string") {
  //         $where += `&[search_areaslist]=${area_name}`
  //       } else {
  //         area_name?.map((item, ind) => {
  //           $where += `&[_or][${ind}][search_areaslist]=${item}`
  //         })
  //       }
  //     }
  //   }

  //   if (page_url_data["buildingval"]) {
  //     $where += `&[building_contains]=${page_url_data["buildingval"]}`
  //   }
  //   if (page_url_data["bedVal"]) {
  //     $where += `&[bedroom_gte]=${page_url_data["bedVal"]}`
  //     sorting = `bedroom:ASC,sort_date:DESC,status:ASC`
  //   }
  //   if (page_url_data["bedVal"] === 0) {
  //     $where += `&[bedroom_lte]=${page_url_data["bedVal"]}`
  //     sorting = `bedroom:ASC,sort_date:DESC,status:ASC`
  //   }

  //   axios
  //     .get(
  //       `${process.env.GATSBY_STRAPI_SRC}/api/properties?_limit=8&_sort=${sorting}&${$where}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //         },
  //       }
  //     )
  //     .then(res => {
  //       // console.log("res =>1", res)
  //       if (res.status === 200) {
  //         getAlgoliadata({ hits: res?.data?.data, total: res?.data?.meta?.pagination?.total,
  //           current_page_number: res?.data?.meta?.pagination?.page,
  //           hitsPerPage: res?.data?.meta?.pagination?.pageSize,
  //           number_of_pages: res?.data?.meta?.pagination?.pageCount, })
  //       } else {
  //         getAlgoliaResutls(myalgoliafilters)
  //       }
  //     })
  // }
  const getFreeText=typeof window!=="undefined"? sessionStorage.getItem("isfreetext"):""

  const getAlgoliaResutls = myalgoliafilters => {
    // lets run algolia search query to fetch hits, stats and number of pages
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_API_KEY
    )
    const index = client.initIndex(
      page_url_data["sortVal"]
        ? page_url_data["sortVal"]
        : page_url_data["indexVal"]
    )
    //const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);
    // let query_filt = page_url_data['areaVal'].split("-and-")
    // let myareaquery = query_filt.map(function(area) {
    //     return area;
    // });
    index
      .search((!getFreeText?"":page_url_data['areaVal'] == (process.env.GATSBY_DEFAULT_AREA).toLowerCase() ? '' : page_url_data['areaVal']), {
        // similarQuery: myareaquery != process.env.GATSBY_DEFAULT_AREA ? myareaquery.join(' ') : '',
        filters: myalgoliafilters,
        page: page_url_data["pageVal"] - 1,
        hitsPerPage: page_url_data["layoutVal"] ? 1000 : 8,
      })
      .then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
        var myArray = {
          total: nbHits,
          current_page_number: page,
          hitsPerPage: hitsPerPage,
          number_of_pages: nbPages,
          hits: hits,
        }
        getAlgoliadata(myArray)
      })
  }
  return (
    <React.Fragment>
      <SearchResultsTemplate
        {...algoliadata}
        pageh1={pageh1}
        introcopy={introcopy}
        page_url_data={page_url_data}
        createResultsUrl={createResultsUrl}
        location_path={props.location.pathname}
        indexname={page_url_data["indexVal"]}
        getAlgoliadata={getAlgoliadata}
        realuser={typeof window !== "undefined" && isbot(navigator.userAgent)}
      />
    </React.Fragment>
  )
}

export const Head = props => {
  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  const { pagetitle, pagemetadesc } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  return (
    <SEOSEARCHRESULTS
      title={pagetitle}
      description={pagemetadesc}
      url={props.location.pathname}
    />
  )
}
